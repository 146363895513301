import React from 'react'
import styled from "styled-components"
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import BlogNewsletterCTA from '../components/BlogNewsletterCTA'

import Facebook from '../images/icon-facebook-sharing.svg'
import Twitter from '../images/icon-twitter-sharing.svg'
import Email from '../images/icon-email-sharing.svg'

const BlogPostContainer = styled.section`
  margin-bottom: 120px;

  .post-featured-image {
    margin-bottom: 40px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
    }
  }

  .container {
    max-width: 800px;
    margin: 0 auto;

    .blog-categories {
      list-style: none;
      padding: 0;
      margin: 0 0 40px 0;
      display: flex;
      justify-content: center;

      li {
        margin-left: 7px;
        display: flex;
        justify-content: flex-start;

        &:first-child {
          margin-left: 0;
        }

        p {
          margin-right: 7px;
          margin-bottom: 0;
        }
      }

      a {
        color: var(--pink);
        text-decoration: underline;
        font-weight: var(--font-bold);
        font-size: 20px;
      }

      span {
        color: var(--pink);
      }
    }

    .blog-post-body {
      color: var(--gray);
      margin-bottom: 50px;
      
      p {
        line-height: 1.4;
        margin-bottom: 30px;
      }

      a {
        color: var(--pink);
      }

      ul,
      ol {
        margin-top: 0;
        margin-bottom: 30px;

        li::marker {
          color: var(--pink);
        }

        p {
          margin-bottom: 0;
        }
      }

      .gatsby-image-wrapper {
        margin-bottom: 30px;
      }
    }
  }

  .post-sharing {
    text-align: center;
    margin-bottom: 55px;

    p {
      color: var(--pink);
      font-size: 23px;
      font-weight: var(--font-bold);
      margin-bottom: 15px;
    }

    .sharing-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }

  .view-more-btn {
    text-align: center;
  }
`

const IframeContainer = styled.span`
  padding-bottom: 56.25%; 
  position: relative; 
  display: block; 
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute; 
    top: 0; 
    left: 0;
  }
`

function Blog({ pageContext }) {
  const { blog } = pageContext

  const website_url = 'https://fintechlegal.io';

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
      [MARKS.CODE]: text => <pre>{text}</pre>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          return null
        }
        return <GatsbyImage image={gatsbyImageData} alt={node.data.target.title} />
      },
      [INLINES.HYPERLINK]: (node) => {
        if((node.data.uri).includes("https://www.youtube.com/embed/")) {
          return <IframeContainer><iframe title="Video Title" src={node.data.uri} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></IframeContainer>
        } else {
          return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
        }
      }
    },
  }

  return (
    <main>
      <PageTitle title={blog.title} paragraph={`${blog.author.name} - ${blog.publishDate}`} />
      <BlogPostContainer>
        <div className="post-featured-image">
          <GatsbyImage image={blog.featuredImage.gatsbyImageData} alt={blog.featuredImage.title} />
        </div>
        
        <div className="container">
          <div className="blog-content">
            <ul className="blog-categories">
              {blog.blogCategory && blog.blogCategory.map((blogCat, index) =>
                <li key={blogCat.id}>
                  {index === 0 ? (<p>Categories:</p>) : ""}
                  <a href={`/category/${blogCat.categorySlug}`}>{blogCat.categoryTitle}</a><span>{index < blog.blogCategory.length - 1 ? ", " : ""}</span>
                </li>
              )}
            </ul>
            <div className="blog-post-body">
              {blog.body && renderRichText(blog.body, options)}
            </div>

            <div className="post-sharing">
              <p>Share This Article</p>
              <div className="sharing-buttons">
                <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?text=${blog.title}&url=https://fintechlegal.io/${blog.slug}`}>
                  <img src={Twitter} alt="Twitter Sharing" />
                </a>
                <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=https://fintechlegal.io/${blog.slug}`}>
                  <img src={Facebook} alt="Facebook Sharing" />
                </a>
                <a href={`mailto:?subject=Check out this FinTech Law Blog Post&body=Check out this FinTech Law Blog Post - https://fintechlegal.io/${blog.slug}`}
                  title="Share FinTech Law Blog Post by Email">
                  <img src={Email} alt="Linkedin Sharing" />
                </a>
              </div>
            </div>

            <div className="view-more-btn">
              <a href='/blog/' className="btn btn-large">View More Posts</a>
            </div>
          </div>
        </div>
      </BlogPostContainer>
      <BlogNewsletterCTA />
    </main>
  )
}

export default Blog

export const Head = (pageContext) => {
  return (
    <>
      <Seo 
        key={pageContext.pageContext.blog.id} 
        title={pageContext.pageContext.blog.seoTitle}
        description={pageContext.pageContext.blog.seoTitle}
        image={pageContext.pageContext.blog.seoImage.url}
      />
    </>
  )
}
import React from 'react'
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const BlogNewsletterCTAContent = styled.section`
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 100px;
  position: relative;

  .newsletter-bg {
    position: relative;
    min-height: 430px;

    @media(min-width: 750px) {
      min-height: 240px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:before {
      background: linear-gradient(to bottom, var(--black), rgba(0 0 0 / 0%) 50%, rgba(0 0 0 / 0%));
    }

    &::after {
      background: linear-gradient(to top, var(--black), rgba(0 0 0 / 0%) 50%, rgba(0 0 0 / 0%));
    }
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    z-index: 2;

    @media(min-width: 1200px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  #newsletter-form {
    text-align: center;

    .ml-form-embedContainer {
      .ml-form-embedWrapper {
        max-width: 300px !important;

        @media(min-width: 750px) {
          max-width: 700px !important;
        }

        .ml-form-embedBody {
          padding: 0 !important;

          .ml-form-embedContent {
            margin-bottom: 0 !important;
          }

          .ml-form-formContent {
            display: flex !important;
            flex-direction: column !important;
            gap: 10px !important;
            margin: 0 0 30px 0 !important;

            @media(min-width: 750px) {
              flex-direction: row !important;
              margin: 0 0 14px 0 !important;
              gap: 20px !important;
            }
          }

          .ml-form-fieldRow {
            margin-bottom: 0 !important;

            input {
              background-color: rgba(0 0 0 / 70%) !important;
            }
          }
        }

        .ml-form-embedSubmit {
          margin-bottom: 0 !important;
    
          button[type="submit"] {
            display: inline-block !important;
            width: auto !important;
            border: 1px solid var(--pink) !important;
            padding-left: 25px !important;
            padding-right: 25px !important;
  
            &:hover {
              border: 1px solid var(--white) !important;
              color: var(--white) !important;
              cursor: pointer !important;
            }
          }
        }

        .ml-form-successBody {
          padding: 0 !important;

          .ml-form-successContent {
            h4 {
              color: var(--white) !important;
              text-align: center !important;
            }

            p {
              color: var(--white) !important;
              font-size: 18px !important;
              text-align: center !important;
            }
          }
        }
      }
    }
  }
`

export function NewsletterCTA() {

  return (
    <BlogNewsletterCTAContent>
      <StaticImage
        src="../images/newsletter-signup-bg.jpg"
        layout="fullWidth"
        placeholder="blurred"
        objectFit="cover"
        alt=""
        className="newsletter-bg"
      />
      <div className="container">
        <div id="newsletter-form">
          <div className="ml-embedded" data-form="H7AfgK"></div>
        </div>
      </div>
    </BlogNewsletterCTAContent>
  );
}

export default NewsletterCTA